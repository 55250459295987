import React from 'react'
import styled from 'styled-components'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { graphql, Link, StaticQuery } from 'gatsby';

// Styled Components For CSS
const FooterComponent = styled.div`
  border-bottom: 15px solid #FFFFFF;
`

const FooterLinkBlock = styled(AnchorLink)`
color: #FAFAFA;font-family: 'PT Serif', serif;font-weight: 300;text-decoration: none;
`

const ExternalFooterLinkBlock = styled.a`
color: #FAFAFA;font-family: 'PT Serif', serif;font-weight: 300;text-decoration: none;
`

const Footer = class extends React.Component {
  render() {
    const { data } = this.props;
    const {
      footerColumn1Title,
      footerColumn2Title,
      footerAddress1,
      footerAddress2,
      footerContactNumber,
      footerContactEmail,
    } = data.markdownRemark.frontmatter.footerSection;

    const {
      facebook,
      instagram,
      brixbrine,
    } = data.markdownRemark.frontmatter.socialLinks

    return (
      <footer className="footer has-background-black has-text-white-ter">
        <FooterComponent>
          <div className="container">
            <div className="row" style={{padding: '100px 0px'}}>
              <div className="col-md-3 col-xl-6">
                <Link to="/">
                  <div id="footer_logo"/>
                </Link>
              </div>
              <div className="col-md-3">
                <div>
                  <h5 style={{color: '#fafafa', fontFamily: 'Poppins, sans-serif', letterSpacing: '.1em'}}>{footerColumn1Title}</h5>
                  <div style={{color: '#FAFAFA',fontFamily: 'PT Serif, serif', fontWeight: '300'}}>
                            <ul className="list-unstyled" style={{color: '#FAFAFA', fontFamily: 'PT Serif, serif', fontWeight: '300'}}>
                                <li><FooterLinkBlock href="#menu_block">Menus</FooterLinkBlock></li>
                                <li><FooterLinkBlock href="#reserve_block">Reserve</FooterLinkBlock></li>
                                <li><FooterLinkBlock href="#about_block">About</FooterLinkBlock></li>
                                <li><FooterLinkBlock href="#events_block">Events</FooterLinkBlock></li>
                                <li><ExternalFooterLinkBlock href="//app.upserve.com/s/crooked-pine-ogunquit">Order Now</ExternalFooterLinkBlock></li>
                                <li><ExternalFooterLinkBlock href="//app.upserve.com/b/crooked-pine/gift-card">Gift Cards</ExternalFooterLinkBlock></li>
                            </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                    <div/>
                    <div>
                        <h5 style={{fontFamily: 'Poppins, sans-serif',letterSpacing: '.1em',color: '#fafafa'}}>{footerColumn2Title}</h5>
                        <div>
                            <ul className="list-unstyled" style={{color: '#FAFAFA', fontFamily: 'PT Serif, serif',fontWeight: '300'}}>
                                <li>{footerAddress1}</li>
                                <li>{footerAddress2}</li>
                                <li><i className="fa fa-phone"/> {footerContactNumber}</li>
                                <li><i className="fa fa-paper-plane"/> {footerContactEmail}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div className="container" style={{borderTop: '1px solid rgba(142,147,150,0.12)'}}>
            <div className="row">
                <div className="col-md-6">
                    <div style={{height: '100px',lineHeight: '100px',whiteSpace: 'nowrap'}}><a
                            href={brixbrine}
                            style={{textDecoration: 'none', color: '#fafafa', margin: '0px 20px'}}>@Brix+Pine, LLC</a></div>
                </div>
                <div className="col-md-6">
                    <div
                        style={{justifyContent: 'flex-end', height: '100px', display: 'flex', lineHeight: '100px', whiteSpace: 'nowrap'}}>
                        <a href={facebook} style={{textDecoration: 'none', color: '#fafafa', margin: '0px 0px 0px 20px'}}>
                      <i className="fa fa-facebook"/>
                          </a>
                      <a href={instagram} style={{textDecoration: 'none', color: '#fafafa', margin: '0px 0px 0px 20px'}}>
                        <i className="fa fa-instagram"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </FooterComponent>
      </footer>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        markdownRemark(frontmatter: { templateKey: { eq: "blank-template" } }) {
          frontmatter {
            footerSection {
                footerColumn1Title
                footerColumn2Title
                footerAddress1
                footerAddress2
                footerContactNumber
                footerContactEmail
            }
            socialLinks {
                facebook
                instagram
                brixbrine
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
);


